import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/docs-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <p>{`This page will give a quick guide on what you'll need to build a keyboard with the nice!60, battery details,
and how to customize your keymap.`}</p>
    <h2 {...{
      "id": "required-parts"
    }}>{`Required Parts`}</h2>
    <ul>
      <li parentName="ul">{`61 Cherry MX Style Switches`}</li>
      <li parentName="ul">{`Cherry MX Compatible Keycap Set with 60% ANSI Coverage`}</li>
      <li parentName="ul">{`4x2U, 1x6.25U Stabilizers`}</li>
      <li parentName="ul">{`3.7V Lithium Rechargeable Battery`}
        <ul parentName="li">
          <li parentName="ul">{`At Least 500mAh`}</li>
          <li parentName="ul">{`2-Pin JST-PH (2.00mm) Connector`}</li>
        </ul>
      </li>
      <li parentName="ul">{`60% Case (Standard GH60/DZ60 Style)`}
        <ul parentName="li">
          <li parentName="ul">{`Keep in mind that a big metal cage is not necessarily cohesive with wireless connections. Metal cases have been tested and work generally the same, but they do impact the wireless performance`}</li>
          <li parentName="ul">{`Make sure thickness of the of your battery and the depth of your case are compatible`}</li>
        </ul>
      </li>
      <li parentName="ul">{`60% ANSI Switch Plate (2.25U Left Shift)`}
        <ul parentName="li">
          <li parentName="ul">{`Recommended to keep the switches more solidly held into the keyboard`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "installing-the-battery"
    }}>{`Installing the Battery`}</h2>
    <p>{`When installing the battery on the nice!60, you'll notice it has a 3-pin JST-PH connector.
This is done to accommodate batteries with the power or ground flipped either way.
Make sure the red wire is being plugged into the PWR pin (in the middle) and the black wire is plugged into the GND pin (on the ends).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "92.02453987730061%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAC4jAAAuIwF4pT92AAAEBUlEQVQ4y02UXU8bRxhG9xdwgxCRUkIhwfh7ba+NP8AfYIztGGxjGwzGDnaA0FIRoEAbEQROWyNRFVHlukhNJK65qEBECj8B0pb/AWqUKBcBTjUDQlnpaFaj3Rm973NmlESin2w2SzqdJh6Pk8/nWVpeplgsEggE6Orqwu32oGkaPT1hpqfKDKYSmC0WOWe327HZbKiqjWQqhZJIJIjHBQ8ZGhrC6/USDAblu8/XiUPTcLvdaE4n/kCAuaffkctlsKoqLpcLzaHhsNkkJoMBJRAMMzLkZ262QHowh9vdQTgcJplM4fZ4mF+YZ2tri+npadp0OlrvP+D+/Qfo2ttpa9PxoF1Pq9VKi8VKq1VF8XoDPB7vY331MYOZITSHHaemYTAYZCkLCwusr69TLJaobWzw5s0b9vb2JH/tH7Dz8iVls4Wy2SxRnE4fTyb6eLE2STw+QDqVYGlxjnw+J0udn59ne3ubyclJjo6OEM/FxQVXl5fy/cPJCSd6/TWiZIfmZfFpnF83JuntjeN0arKPHo8Hv7+LZ89+5PftbcbHxzk8PLxe5MMHPn78yOXVFX+/e0cyFrtF0TQ387Mj/LA0QVdXEJtNJRQKycQdDgejo6NMTU0RiUTY39/n8+fPnJ2dcX5+zqdPnzg+OWEon5cMj4yg+Hw+VJvGvXtfYzDoaW5ulrS0tNDY2MidO3doa2tDr9cTjcUYHh6WmmUyGXK5nNzYZDRhNpnkN4qY6Onppr29XS7Q1NSETqfj7t2vSCaTVKtrjI0VSKWSxGJRvF6f1Ek46vf7JUIf4aPQTenv72dgYADhY084LHetVquUy+Vr0YeHZbni40hfRIbWHfJjMplQVVWa4LgRXPwjF0ynMySTaXlK+vr6iMVi5HJZRDuMRiMWi1WO5XKFV3/+Qe2XF1Qqj5mZmaFQKGBTVVSrFaNIubsnQiblpzKeIZUalDsKscXiImmhy/r6mjw5IuWrK/jv/XuZstDn39NT8sWiZKRUEqEEqTyKUF2dIJvNS21EKaLBIuXFpUVqtRqFwhj7Bwe32gguLi/55/iYbChENhiSo+J0+fhmMsrP1SfEHyZJJRMsfj9LLjeIy9XB0vKSFFuo8/ZLsS8ubsU+1euvESVrTh/fTkX4aW2caLQfl8tJMBiQ/evs7GRubo7NzU0qlTIrKyvs7u6ys7MjefX6Nb/VamTs9lsUu93N2vMxNjdm6ewMyStJiB2NCkU8csHV1eeUSiXZBnHGxSgQqumNRox2O4YbFHHMOtw+zObr+01IbLFYZDhCauGmmDMKTWy2m7tPvcVqtWK1WGTKAqW3N4LP55VC19fX09DQIMe6ujpZdulRSWokPBQyS6G/kFq0xW533Dr5P9Pu3EpcSMaXAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Battery Plug in Configurations",
          "title": "Battery Plug in Configurations",
          "src": "/static/42ba79c7ea8f3ccba827a477a630f363/a6d36/nice60_battery_install.png",
          "srcSet": ["/static/42ba79c7ea8f3ccba827a477a630f363/222b7/nice60_battery_install.png 163w", "/static/42ba79c7ea8f3ccba827a477a630f363/ff46a/nice60_battery_install.png 325w", "/static/42ba79c7ea8f3ccba827a477a630f363/a6d36/nice60_battery_install.png 650w", "/static/42ba79c7ea8f3ccba827a477a630f363/5ebd7/nice60_battery_install.png 704w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "important-note-about-metal-cases"
    }}>{`Important Note About Metal Cases`}</h2>
    <p>{`If you're using a metal case with your nice!60, please take care to `}<strong parentName="p">{`insulate the middle post and not tighten the screw too much`}</strong>{`.
This post unfortunately cannot have the same through hole pad protections that the rest of the posts have due to the hotswap sockets.
Failure to properly insulate the middle post on metal cases can result in the "6yhn" column breaking due to shorts.`}</p>
    <h2 {...{
      "id": "assembling-the-keyboard"
    }}>{`Assembling the Keyboard`}</h2>
    <p>{`Here is a building stream of a nice!60 to guide you with your build.`}</p>
    <br />
    <div style={{
      position: 'relative',
      width: '100%',
      height: 0,
      paddingBottom: '56.25%'
    }}>
      <iframe width="100%" height="100%" style={{
        position: 'absolute',
        top: 0,
        left: 0
      }} src="https://www.youtube.com/embed/Ug-Xmkm0Qgs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    </div>
    <h2 {...{
      "id": "default-keymap"
    }}>{`Default Keymap`}</h2>
    <p>{`The nice!60 is preloaded with the ZMK firmware using the below keymap. Read more about `}<a parentName="p" {...{
        "href": "https://zmk.dev"
      }}>{`ZMK here`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.71165644171779%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACO0lEQVQ4y11T2ZKbMBDkU1K1sTGHuATYYE4Dvo/11m6SylP+/yc61YPNbvIw1ZKmpWlpWobSG9jhBnbQQulexq7uYIctnGjMERlW0MCNOuFybnqVoBtt0O5/yT4jWO6hkh0cPUClI7rxFk48wCHqAXbUC2dCPaIVdlDpHqbfIlpf4KU7GH66Q7A6yiIJi4CqOhlbwUbmVriZcl+R647uhccCFGLE6zPy7h121ElSJVtJPFUS/1EX9bJmRR3cZCtC9PqCbPMuecNLBuj1VaqOKomNxNxrZI3BMXMzVQt37tWTUh4YZicRZej8hLi4yiIreMsDvHSPtLojLm6Iy89IqlfheXz3dI8oP8uepLxBF1c51DBViRd7DSto8d0pYErlBlF+QrA6SISP4JhvTt5cVcKduaXEN3OFhVfDsIMaC7+G6ZWw/BoLr8LCrzBzC8wZqsTMWU9j4fgV7LABxdBKXtxhe/4tNjKi7CjXFAvIow9ilUSe4Yy4uAim1W20V7wdG0PbPJDzZf0Gf3mAoR6dnLm8Qou5olpe+Qh6lAXphGV1FTuxKQw2iiiN8xq4D7+KQj42J1TGhkjF6oaif0fW3FENH6i2P5C1b9LZp7mpjgfXu5/Y3f6MtlHJgKQcD3za5H+7jPMGZtBOtiFaD8XsbpRfRoU6PyLMzqKMhKeRaRnaYlnfUfQfYpen38gNs+P0LYPVSbh0gcHP/+KUUzX5eroXb0VsRv0qV9VsTnFBmJ9EOX/V19vMVCVv/Bcilszm3v6sxgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Default Keymap",
          "title": "Default Keymap",
          "src": "/static/7449e9207a8c65a4c99e19bf4d17522d/a6d36/nice60_keymap.png",
          "srcSet": ["/static/7449e9207a8c65a4c99e19bf4d17522d/222b7/nice60_keymap.png 163w", "/static/7449e9207a8c65a4c99e19bf4d17522d/ff46a/nice60_keymap.png 325w", "/static/7449e9207a8c65a4c99e19bf4d17522d/a6d36/nice60_keymap.png 650w", "/static/7449e9207a8c65a4c99e19bf4d17522d/e548f/nice60_keymap.png 975w", "/static/7449e9207a8c65a4c99e19bf4d17522d/3c492/nice60_keymap.png 1300w", "/static/7449e9207a8c65a4c99e19bf4d17522d/7f53b/nice60_keymap.png 3049w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "managing-wireless-profiles"
    }}>{`Managing Wireless Profiles`}</h2>
    <p>{`Check out the `}<a parentName="p" {...{
        "href": "https://zmk.dev/docs/behaviors/bluetooth"
      }}>{`ZMK docs`}</a>{` on how the wireless BLE works.`}</p>
    <p>{`Generally to manage your profiles, you will use the first column of keys on the left side of the board on the function layer.
Press the `}<inlineCode parentName="p">{`Fn`}</inlineCode>{` key and the `}<inlineCode parentName="p">{`Esc`}</inlineCode>{` key to erase the bond/device on the current active profile and go back into pairing mode.
Press the `}<inlineCode parentName="p">{`BT 1/2/3/4`}</inlineCode>{` keys on the left side on the `}<inlineCode parentName="p">{`Fn`}</inlineCode>{` layer to switch between profiles. `}</p>
    <p>{`Extra notes:`}</p>
    <ul>
      <li parentName="ul">{`You can't connect the same device to multiple profiles. If you're having troubles pairing, try clearing the rest of the profiles.`}</li>
      <li parentName="ul">{`Don't disconnect the nice!60 from your computer or other device. This clears the bond information and means the nice!60 will refuse to reconnect until you press the `}<inlineCode parentName="li">{`BT CLR`}</inlineCode>{` button.`}</li>
    </ul>
    <h2 {...{
      "id": "creating-a-custom-keymap-and-firmware"
    }}>{`Creating a Custom Keymap and Firmware`}</h2>
    <p>{`Of course, the default keymap will not be perfect for everyone, so you can update your keymap and build new firmware without installing anything on your computer.`}</p>
    <p>{`You will need a GitHub account to do this.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Navigate to `}<a parentName="p" {...{
            "href": "https://github.com/Nice-Keyboards/nice60-zmk-config"
          }}>{`https://github.com/Nice-Keyboards/nice60-zmk-config`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Press the Fork button in the top right
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "53.37423312883436%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABf0lEQVQoz32Q627jIBCF/Sa7NbcBZrgZnGASu920m6r91/d/mJVJVEX9sdInNILhnDkztOf34/pW178uLz63/zEtYd5Su6a65vPmchvSvIZyjuVk/fwD42fjitnrg+21i7VNrYbSpgO5MjwJOwr7JCyTyCQxSaPEUSIHX5bX08tHWS4+t1JfDtv7lupXOHy0189wPDI9SBM4OA5eaP9YCB2AsqasbBTaSxsUJgv+JGwDtwg0EgdNWe7PwbgiTRDaAyYOXpqoMDFFo7RMIVPEJI6Kfnd+KRoVDX1I6k0otKdYKVXtCqVKse7+mIT27C6BXO6oPu+wSyrXTxQ6zO2ybFealmW7Hs9v0sQ4nzEuXCdhpk7mEJWN++ee0wnjNU2AE2CSJhq3pxV7CgdUTLqYdIGw6vCswx9FtWehmzMxIK73VUm9x1aYukrg4JWNgJEDjtLcuK9A0d2Zg7urdJSNxs/aFQ4OMCk7fbc9Ng8/bm9IEzRNChPXPuRTyO1R+pt/03R1HeUhOKwAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Step 2",
              "title": "Step 2",
              "src": "/static/4c5f2b061aab79e27b3d553c19168f6c/a6d36/nice60-custom-firmware-step2.png",
              "srcSet": ["/static/4c5f2b061aab79e27b3d553c19168f6c/222b7/nice60-custom-firmware-step2.png 163w", "/static/4c5f2b061aab79e27b3d553c19168f6c/ff46a/nice60-custom-firmware-step2.png 325w", "/static/4c5f2b061aab79e27b3d553c19168f6c/a6d36/nice60-custom-firmware-step2.png 650w", "/static/4c5f2b061aab79e27b3d553c19168f6c/5bb8b/nice60-custom-firmware-step2.png 749w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`After forking is complete, click on the Actions tab
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "649px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "61.963190184049076%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAABkUlEQVQoz4WQuXLbQBBE8SkEsPfO3gcO4iBNABIl+Q+cOfX/xzYES3Jgy1UvmAm6q7uLdly/ff+hfZfaWehIwTMIBxQ81+l8uc/L127a+vmhm7b59jwvL+24Dtd7oUM/XB+5jmAzA0flB7uFCjYNsbu6NPo82TT4ZordxabB5amoCSDZCr9wM6n8DOkO6QHiBuleE6ipKrE8IVFieXBC4nhPSBSYG59nHS7cfZHxSfhNhJWFjYe1JLKkqqSqprtLTVVFfh+Hb1FTRaUHmzE3WFgKDoPvEV8Qn6haKxq4wRAIN5hbBgExTYWj0mNuCkS1MzGnXoIjwjHwWMWhFjcsRqa2ikZusY5EWCIcVxExQ+U+Kua2QExLk6XNQiciLRF295aOM0OxxASqN95jv7975zwsw/Up9zeuIoNAhBM6Mh2rP0v+jdfO4DnEHRWlSdLsKfY4Zh8CMf0vdjGDCLaRbwJpGrCtdh3Y5v9iYRwRFvMPENOvwTRi5hOKiqpmOPtm9Hk2cbDx7NIoTT7EnxT+1fknOzeAen2GEesAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Step 3",
              "title": "Step 3",
              "src": "/static/5bf8370db559641ff161fdf4b4bab6d7/7762d/nice60-custom-firmware-step3.png",
              "srcSet": ["/static/5bf8370db559641ff161fdf4b4bab6d7/222b7/nice60-custom-firmware-step3.png 163w", "/static/5bf8370db559641ff161fdf4b4bab6d7/ff46a/nice60-custom-firmware-step3.png 325w", "/static/5bf8370db559641ff161fdf4b4bab6d7/7762d/nice60-custom-firmware-step3.png 649w"],
              "sizes": "(max-width: 649px) 100vw, 649px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click on the "I understand" button to enable building your firmware using GitHub Actions
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.282208588957054%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAABQUlEQVQoz5WQ227DIAxA+ykbCWCwgQAhCepFm9ruYS973B62rJX6/98wJWl36apVk44s23DAMAM3lyZLjCVYrlwJthzjMT/BlSukOWPGlSUTmmauTQCKQleSAlcOKAw5eolBogcTL8jHTFAhDRNUTEz5uMR+lpfkkRLsVwnTnHRq2isyE3TLkQliApm0Oj9zam+5Hjv0+/LZ5EyaRI82AXpFAdBT2qJfKAoDJkr0Z6cMskAv0HNV2ZhDvrf10sSFSysTMvns0sqlVdXcubSQ406JfnrLKOtKmRqrVlOdfJ3bJjepS3Vu2tw0A23bxZpMrW1ClwT64lM+ji2JM/24dP1h/fq+7vfbt92m3z/0u01/2L48taZUN8Kcj/0dkARcK3BIHWFHlLWKwDUIKv/+7WEKsAVYNmAY0BjN1Cmuyv/iAy9OesDl6kQlAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Step 4",
              "title": "Step 4",
              "src": "/static/059a6a10625f278c9bb8806481dd50ef/a6d36/nice60-custom-firmware-step4.png",
              "srcSet": ["/static/059a6a10625f278c9bb8806481dd50ef/222b7/nice60-custom-firmware-step4.png 163w", "/static/059a6a10625f278c9bb8806481dd50ef/ff46a/nice60-custom-firmware-step4.png 325w", "/static/059a6a10625f278c9bb8806481dd50ef/a6d36/nice60-custom-firmware-step4.png 650w", "/static/059a6a10625f278c9bb8806481dd50ef/146da/nice60-custom-firmware-step4.png 943w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Next click back to the Code tab to the left of the Actions tab
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "63.80368098159509%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAABx0lEQVQoz33Ry2obMRQGYD2Kx7rfpZE0nptnhowzdmOTRQOFQOgiTUuhr9TSLrJpn7LEdkzi0MK3kOD8OtIRmHY30/YmL1oXaqYC1/GAqeBi26923bituqnuN924Gy6vy27qxiuXWhsbEMo+LzphIteBSvfMU+GFKWxqXWxMXptQ29i41Jq8crERNgmbAGSG5SPzo4wbs7hW6d3eGhIFqc6wfEHMkMiIyrCEREOqgZJ+4Suva+EG5gZme2Z7ousMC0ifKub7U05O2zlRYDXDHRZrSAsioPJcecy0zRehaJn0whZMB8ydMAlzS2XOdcTcMpVzHYCHfDFnFeKCaigc5pYwY3XuXaLcHPsQhblFzCBmjgtusXCA6GBSi4SfYZEhJl0Z262v1szEUPZFc1F1k7TpkHx5f0gUcMu77effV/c/+9sfzYfv3e2v4e7x4uMfXb4X2utQm7wSNnETifCvwlQD7ldp9a24/Or7B9c/2O6T7e5d/4WaJaIKMYuYxdwh9lR96H8CIBGIUIhJNocZRM8gpFLvv9TGRvlSuuItAKk5Yq8gZkI5NMOmXE4utcqXOq/OgLNnnBzGk2E5J/JfNf8Ln4/3TfgvTS2RnYgT/gsAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Step 5",
              "title": "Step 5",
              "src": "/static/1dd957e245dedd85ee10b5134b683ffc/a6d36/nice60-custom-firmware-step5.png",
              "srcSet": ["/static/1dd957e245dedd85ee10b5134b683ffc/222b7/nice60-custom-firmware-step5.png 163w", "/static/1dd957e245dedd85ee10b5134b683ffc/ff46a/nice60-custom-firmware-step5.png 325w", "/static/1dd957e245dedd85ee10b5134b683ffc/a6d36/nice60-custom-firmware-step5.png 650w", "/static/1dd957e245dedd85ee10b5134b683ffc/774b6/nice60-custom-firmware-step5.png 738w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Now we can edit the keymap. Click on the `}<inlineCode parentName="p">{`config`}</inlineCode>{` folder
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "55.21472392638037%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPUlEQVQoz32S2WojMRBF9Su2u9UlqRbtLbdCOsQhC/Mw+f+vCW2D8STjwHkqOFzVLSnAVB9eaj8BZUN5ctFKGYAPE91jNzr087J+KG09p0VSt1w2KFsuBtNoZAD+yWhEW28oYzyqAzBgsudYJ9VJQd8oNG39f2XtgqGsrddGlHaCIRkqgBEwGkrnN+O3tFs204XJRaVd8KVRPHLuHI+Wywiy13h1BuC9xiuHiTb57Kts5JXLiwudiwtHbQNQAkoYZsldcscwXyZXJowXVAP6CO2zrc9SDVcn1UqxnDktsa2hPkpetskN52o21B54MAKUJ8r/7uknF7QN95obgNVoRFKnMO817kZ3WWw3utvD3pUHYCdVcqfYDCbLW+3o59HIL//kwiantj6e/vSnt/703tf32k/Pb38lLd/yf8pfwv9zMPZqwxsAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Step 6",
              "title": "Step 6",
              "src": "/static/858fc8609f61d7038b21b76ad954509a/a6d36/nice60-custom-firmware-step6.png",
              "srcSet": ["/static/858fc8609f61d7038b21b76ad954509a/222b7/nice60-custom-firmware-step6.png 163w", "/static/858fc8609f61d7038b21b76ad954509a/ff46a/nice60-custom-firmware-step6.png 325w", "/static/858fc8609f61d7038b21b76ad954509a/a6d36/nice60-custom-firmware-step6.png 650w", "/static/858fc8609f61d7038b21b76ad954509a/73fd0/nice60-custom-firmware-step6.png 793w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Now click on the `}<inlineCode parentName="p">{`nice60.keymap`}</inlineCode>{` file
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "63.80368098159509%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRklEQVQoz42S2U4DMQxF51egEydeEsdJZoEWURXxwDsS//8naDospWWTrqJYkX1ubHeYZi4PVA4yPFF5kPaEtr8G3vj4pzrWKZa7oPdkB7Q92t6n7fEt9SFtfDw9F4W4hn1IHaCKNhcEKHnW3jOQAhtQptQcapAKbJ7NS11DzwZsGFuXdBjaLafWUwVujhpw8VKOqHQCXO79O3O10KW6nXaP2nY+Lea97jHvtN5ufLwG+ePPnk1spjSwtmij5EHyKHmKNjvMK+cndUA5SAEyIPNcgC1IDVKCFIf6uzoptUxbyTMvtBvW4cztB+cb246yo9yjLvBjk8+8XVb5tO1QJVbV0fu4uejQMptYQ6zv/f9KjiE9o76k8QXEHF2hupPaQJnzeNR02b9lSVTHbDN+TXtLxsw6kg6YBod6nrwuwDLVfyzzme1XNhSC3U6rSmAAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Step 7",
              "title": "Step 7",
              "src": "/static/ab26c25750ca531ea623ba659b99bb88/a6d36/nice60-custom-firmware-step7.png",
              "srcSet": ["/static/ab26c25750ca531ea623ba659b99bb88/222b7/nice60-custom-firmware-step7.png 163w", "/static/ab26c25750ca531ea623ba659b99bb88/ff46a/nice60-custom-firmware-step7.png 325w", "/static/ab26c25750ca531ea623ba659b99bb88/a6d36/nice60-custom-firmware-step7.png 650w", "/static/ab26c25750ca531ea623ba659b99bb88/64d87/nice60-custom-firmware-step7.png 818w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click on the edit button
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.44171779141104%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAy0lEQVQoz6XRTU4DMQwF4FyExWQSJ3Fs529mQqdsqooLcP/DoLQrCpQC0rewFD/pWVGAeXYCmG1IFjPEYkNyVE1ILtbLK38ANNmogWbHiuuBSrchzY5u9z7RQJi2/vKaliPXXcW0oaw2JOPlO6PUlRfPbSTLM8qqNIwOd5LGi4s1cPPcPA2AmUtHWdT92NVN83H2ZVZU+qPyJm1f99N2PL9RbbmrwMvjUFapB6n7ySfmRWmgX5lsnGx8cqQdqx+/50vGsfHyx/D8//A7wsltJwsZwpQAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Step 8",
              "title": "Step 8",
              "src": "/static/f3886303c746464e60a8e4ec81e46caa/a6d36/nice60-custom-firmware-step8.png",
              "srcSet": ["/static/f3886303c746464e60a8e4ec81e46caa/222b7/nice60-custom-firmware-step8.png 163w", "/static/f3886303c746464e60a8e4ec81e46caa/ff46a/nice60-custom-firmware-step8.png 325w", "/static/f3886303c746464e60a8e4ec81e46caa/a6d36/nice60-custom-firmware-step8.png 650w", "/static/f3886303c746464e60a8e4ec81e46caa/bcb8c/nice60-custom-firmware-step8.png 879w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Edit the keymap as wanted. Use the `}<a parentName="p" {...{
            "href": "https://zmk.dev/docs/codes/"
          }}>{`ZMK codes docs`}</a>{` to find standard HID input options you have for your keymap and check the `}<a parentName="p" {...{
            "href": "https://zmk.dev/docs"
          }}>{`Behaviors category`}</a>{` on the left to find other ZMK specific behaviors.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`When done, scroll to the bottom and commit your changes. Feel free to add a commit title and description to track your changes
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.282208588957054%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUUlEQVQoz52SzYrcMBCE/SJZ1vprSd1qybZke4aZHS/s7B+5hb3mEsj7P0KwxrMJOYQh8FEUyAVFtZvl+QzUdfnYleMwL105Sgg30txLVMDCUCUIE1pNN9IoG5RlZeOGi9qtRhhqNf6bRsIavlf+P9jCwpAEupa/lS0c+t0wnfK8pHwA7I1PBjtLfX0NErguxH9QB5OGADufJh8Lpgnj6Ln4OHku2kVhsNUkDK7VVt3YwgKCguRsJj9y2MVyCP0+5mMc9sjFcfarFoOjocnQDLQzNF3DOjz24e15en/Zvz2NyzD35eA5Wxo85xrOLmRDI9BYdQIq9UDcCEVfT+nj5/njx/nb9+W1H+bxoZ4Kr8PgZ235V21pgxUev4C/A7xzSmNrSMHl+J96MSzht5FAjfFJOG5dbH1qHUvX1S+iBFY2rab+PBJIWdY+XWZXrtNYfgFCWHcVqmEFHwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Step 10",
              "title": "Step 10",
              "src": "/static/6b2ed6d7a14ca355d242171aef2e0df9/a6d36/nice60-custom-firmware-step10.png",
              "srcSet": ["/static/6b2ed6d7a14ca355d242171aef2e0df9/222b7/nice60-custom-firmware-step10.png 163w", "/static/6b2ed6d7a14ca355d242171aef2e0df9/ff46a/nice60-custom-firmware-step10.png 325w", "/static/6b2ed6d7a14ca355d242171aef2e0df9/a6d36/nice60-custom-firmware-step10.png 650w", "/static/6b2ed6d7a14ca355d242171aef2e0df9/d48f1/nice60-custom-firmware-step10.png 796w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your new firmware should now be building. The first build will take a while. Click on the Actions tab to see the status. When it's complete it will have a green check instead of a yellow dot. If you have an error, you've probably missed a comma, semicolon, or typed in a non-existent key type. Check `}<a parentName="p" {...{
            "href": "https://zmk.dev/docs/troubleshooting#dtlibdterror"
          }}>{`this for more information`}</a>{`. Your `}<inlineCode parentName="p">{`nice60.dts.pre.tmp`}</inlineCode>{` file is printed in the step after build.
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "649px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "61.963190184049076%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAABkUlEQVQoz4WQuXLbQBBE8SkEsPfO3gcO4iBNABIl+Q+cOfX/xzYES3Jgy1UvmAm6q7uLdly/ff+hfZfaWehIwTMIBxQ81+l8uc/L127a+vmhm7b59jwvL+24Dtd7oUM/XB+5jmAzA0flB7uFCjYNsbu6NPo82TT4ZordxabB5amoCSDZCr9wM6n8DOkO6QHiBuleE6ipKrE8IVFieXBC4nhPSBSYG59nHS7cfZHxSfhNhJWFjYe1JLKkqqSqprtLTVVFfh+Hb1FTRaUHmzE3WFgKDoPvEV8Qn6haKxq4wRAIN5hbBgExTYWj0mNuCkS1MzGnXoIjwjHwWMWhFjcsRqa2ikZusY5EWCIcVxExQ+U+Kua2QExLk6XNQiciLRF295aOM0OxxASqN95jv7975zwsw/Up9zeuIoNAhBM6Mh2rP0v+jdfO4DnEHRWlSdLsKfY4Zh8CMf0vdjGDCLaRbwJpGrCtdh3Y5v9iYRwRFvMPENOvwTRi5hOKiqpmOPtm9Hk2cbDx7NIoTT7EnxT+1fknOzeAen2GEesAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Step 11",
              "title": "Step 11",
              "src": "/static/5bf8370db559641ff161fdf4b4bab6d7/7762d/nice60-custom-firmware-step3.png",
              "srcSet": ["/static/5bf8370db559641ff161fdf4b4bab6d7/222b7/nice60-custom-firmware-step3.png 163w", "/static/5bf8370db559641ff161fdf4b4bab6d7/ff46a/nice60-custom-firmware-step3.png 325w", "/static/5bf8370db559641ff161fdf4b4bab6d7/7762d/nice60-custom-firmware-step3.png 649w"],
              "sizes": "(max-width: 649px) 100vw, 649px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If your firmware builds properly, click on the build title
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "62.576687116564415%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAABZklEQVQoz4WRS44UMQyGc5SuysPOw07qmaqa0QxdwGIGDRILJDZcAMGaG3ABTsBNUVWYFq1uBumT5cT+49gWYXznurdheNB+qI2XQLUJEkhe2j1Uov18zLdvBPKElJEmy1nbqF0yLilkbXf7x4/laFzSNu6v+Eo7gdRrl7xPebwh6vQW3jIwdMUal8A3GFrjElJvfKOQFLJCFoeqsiFyk8FFH4eQMreztvHUwiWlBQkk8tOPu48/X3/+lR++c5uHZR2WNaRsudc2lgoFCXRyNpBFs35Lr76k49cwf5Lg99mETQasnitcpTZBVFJVSu8Ym3K7rM1079vZNZNrprCRr8LdIhTGQg2cY3/Md7fdzRTHzH0OnYtjSPkq3M6i/EEBHYBXgKfUPcbxfTt/6JZHIGmjPI1Ke2VbQ3OtfbkRf7cBQIyRkQmJkb1L+2L4X5yJa6DqmQNQdb6YS87E6gL5IkL+L+MF8W+KYoZT+q0ytwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Step 12",
              "title": "Step 12",
              "src": "/static/9011d049f65e0037b1abb3f370cf4164/a6d36/nice60-custom-firmware-step12.png",
              "srcSet": ["/static/9011d049f65e0037b1abb3f370cf4164/222b7/nice60-custom-firmware-step12.png 163w", "/static/9011d049f65e0037b1abb3f370cf4164/ff46a/nice60-custom-firmware-step12.png 325w", "/static/9011d049f65e0037b1abb3f370cf4164/a6d36/nice60-custom-firmware-step12.png 650w", "/static/9011d049f65e0037b1abb3f370cf4164/ad12c/nice60-custom-firmware-step12.png 856w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Now click the `}<inlineCode parentName="p">{`nice60-zmk`}</inlineCode>{` link to download your new firmware
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "69.32515337423312%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABXUlEQVQoz42S207DMAyG9yIwlpOT2M6hh3XtNoTGYGJI3MD7PwtaO5X2YhrSd2E5+f0rf7zwoca4dlxqlySwMCQNSWAJpGxQNkjga9H3p50FpgbTGnPjuDI+UW4wrn2oHNfSJmWDj7WyrGzE1GiXlI2ART+dF8MMZYMABsqp6ji3GCtMraFGuxCrrfHJ+MS5VTZol/oRvfMwY0DZYHwWhlYaB4ShJ+WFGQsaTodiJtYuhaJbaXxYwchSultMxWR8bp9Pze4IWF6gErgUhh6FvSMWhixXL59fx4/vt/PP/vC52Z+2L2fH9S3/ibMhwGKzf9/s3uvute4OdXdYb49A5X2xsuGak8IxsJXGpfT/eLMNwtBSujHnu8zSBipyvQfM4+dPGRdrZCY2mKloNWbl07R/i7kzFhGLZKMHFvN7oo8TsBDXzZ+LL8dcfmH68fGo3BJYzfSXLXBcSvgT/wKzo4ghf4RgXQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Step 13",
              "title": "Step 13",
              "src": "/static/2b84713012423c3d5c38bbe8eb94d051/a6d36/nice60-custom-firmware-step13.png",
              "srcSet": ["/static/2b84713012423c3d5c38bbe8eb94d051/222b7/nice60-custom-firmware-step13.png 163w", "/static/2b84713012423c3d5c38bbe8eb94d051/ff46a/nice60-custom-firmware-step13.png 325w", "/static/2b84713012423c3d5c38bbe8eb94d051/a6d36/nice60-custom-firmware-step13.png 650w", "/static/2b84713012423c3d5c38bbe8eb94d051/4ef49/nice60-custom-firmware-step13.png 890w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Unzip the files. You should have a `}<inlineCode parentName="p">{`zmk.uf2`}</inlineCode>{` file, which you'll use`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Take your nice!60 and double-press the reset button on the back below the USB-C port and plug in the board to your computer`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You should get a new drive called `}<inlineCode parentName="p">{`NICE60`}</inlineCode>{` if you properly double-pressed the reset button. Drag and drop or copy the `}<inlineCode parentName="p">{`zmk.uf2`}</inlineCode>{` you have over to that drive
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "287px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "32.515337423312886%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAABKklEQVQY03XQ0UrCUBjA8V2bOBnMbebx+465nTOzUC8iQXsA6SJ6gQi0eofqQoK2oUyY7CzcqO2NgqDnibYKEfrxcTjng3Pzl+gWQARAAEBEpPTgf61Wi1IqUUoRkRDSaJBBt3s2Gp6cDvv9Xodb1WpVluXi3FapVFRV/f6saRohpG1aDUMfXFyt49eXKArDUIjQ8zzXdVzXdZxnx3EWOc/zgiCYTqe6rksIeP/w+P7x+TSf9y6v13GaxJssTYUQURTFmzhJ4rc0y7J0tfJ9318ul0KI2WxGCJE445Pzyc3d7Wg87jKTtdvMto+PDi3Gbc4AgFJgjHdsXi7vFUqlkqIopmlKzWZzv17XajXDMCDvBLmi2U/IfLETDAAkzBX1ivtffMRi8Pe56wsJk18DtAYP5gAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Step 16",
              "title": "Step 16",
              "src": "/static/8c9bf96a3867248be96fdb9a7c2cedf0/480fd/nice60-custom-firmware-step16.png",
              "srcSet": ["/static/8c9bf96a3867248be96fdb9a7c2cedf0/222b7/nice60-custom-firmware-step16.png 163w", "/static/8c9bf96a3867248be96fdb9a7c2cedf0/480fd/nice60-custom-firmware-step16.png 287w"],
              "sizes": "(max-width: 287px) 100vw, 287px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`After it's done your board should auto boot back into your new ZMK firmware. You're done!`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      